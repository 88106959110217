import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PageHeader from "../../components/pageheader"
import FluidImage from "../../components/FluidImage"
import contentParser from "gatsby-wpgraphql-inline-images"

const Page = ({ pageContext }) => {
  const {
    page: { title, content, featuredImage, pageBgColor },
  } = pageContext

  // console.log(pageContext)
  const pluginOptions = {
    wordPressUrl: `https://admin.equityalliancemn.org/`,
    uploadsUrl: `https://admin.equityalliancemn.org/wp-content/uploads/`,
  }

  console.log(title)
  
  return (
    <Layout>
      <style jsx>{``}</style>
      <SEO title={title} />
      <div className={pageBgColor.selectABackgroundColor}>
        <PageHeader
          subTitle="Equity Alliance MN"
          headingTitle={title}
          theme="about"
        />
        <div className="container main-bg">
          <div className="main-section">
            <FluidImage
              image={featuredImage}
              style={{ marginBottom: "15px" }}
            />
            <div className="small-container">
              {/* <h1>{page.title}</h1> */}
              {/*<div dangerouslySetInnerHTML={{ __html: content }} />*/}
              {contentParser({ content }, pluginOptions)}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
